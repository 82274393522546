import styled from 'styled-components'
import ShowMore from '@material-ui/icons/ExpandMore'
import Tooltips from 'components/Tooltips'
import { unnest } from 'ramda'

const Container = styled.div`
  margin: 12px 0;
  position: relative;
  border-radius: 10px;
  background: #842008;
  height: 43px;
  padding: 0px 10px;
  color: ${({ placeholder }) => (placeholder ? '#cccccc' : '#fff')};
  text-align: center;
  font-size: 1.125rem;
  font-weight: 700;
  box-sizing: content-box;
  text-align-last: center;
  width: calc(100% - 20px);

  select {
    font-size: 13px;
    opacity: 0;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    -webkit-appearance: none;
    -moz-appearance: none;
    ${({ label }) => (label ? 'margin-top: 12px;' : '')}
  }

  &::placeholder {
    font-size: 1.125rem;
    font-weight: 700;
    color: #cccccc;
  }
`

const Display = styled.div`
  height: 100%;
  font-size: 13px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 32px;
  padding-left: 32px;
`

const Icon = styled(ShowMore)`
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translate(-50%, -50%);
`

export default function Select({ wrapperStyle, ...props }) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        ...wrapperStyle
      }}
    >
      {props.label && (
        <label {...(props.id ? { htmlFor: id } : {})}>{props.label}</label>
      )}
      <Container placeholder={!props.value} style={props.style}>
        {props.tooltip && (
          <Tooltips
            style={{
              zIndex: 1,
              position: 'absolute',
              right: '-4px',
              top: '-8px'
            }}
          >
            {props.tooltip}
          </Tooltips>
        )}
        <Display>
          {unnest(props.children).find(
            child => child.props.value == props.value
          )?.props.children ||
            props.placeholder ||
            unnest(props.children)[0]?.props.children}
        </Display>
        <Icon />
        <select {...props} style={props.inputStyle || {}} />
      </Container>
    </div>
  )
}
