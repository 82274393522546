import { useEffect, useState } from 'react'
import styled from 'styled-components'
import Button from 'components/Button'
import { Portal } from '@material-ui/core'

const Backdrop = styled.div`
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Square = styled.div`
  transform: translateY(-0.5rem);
  padding: 0.3rem;
  margin: 0.3rem;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  background: ${({ color }) => color};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 0.9rem;
  text-transform: none;
  color: ${({ iColor }) => iColor};
`

const TooltipWindow = styled.div`
  width: 80%;
  padding: 1rem;
  background: white;
  color: black;
  line-height: 2rem;
  > * {
    color: black;
  }
`

function Tooltips({ children, color = '#cccccc', iColor = 'black', ...props }) {
  // Tooltip
  const [showModal, setModal] = useState(false)
  return (
    <>
      <Square
        color={color}
        iColor={iColor}
        onClick={() => setModal(true)}
        {...props}
      >
        i
      </Square>
      {showModal && (
        <Portal>
          <Backdrop>
            <TooltipWindow>
              {children}
              <Button
                background="#ccc"
                color="#000"
                weight="700"
                width="100%"
                onClick={() => setModal(false)}
              >
                Close
              </Button>
            </TooltipWindow>
          </Backdrop>
        </Portal>
      )}
    </>
  )
}

export default Tooltips
